import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "gatsby-theme-wirehead-bulma/src/layout"
import TwoCol from "gatsby-theme-wirehead-bulma/src/twocol"
import SEO from "gatsby-theme-wirehead-bulma/src/seo"
import { MDXRenderer } from "gatsby-plugin-mdx"
import SocialMenu from "../components/socialmenu"
import Helmet from "react-helmet"
import Breadcrumbs from "gatsby-theme-wirehead-bulma/src/breadcrumbs"

export default function PageTemplate({ data, location, pageContext }) {
  const { mdx, allSitePage } = data // data.markdownRemark holds your post data
  const { body } = mdx

  var pages = data.allSitePage.edges.map(article => {
    return (
      <li>
        <Link
          to={article.node.path}
          key={article.node.path}
          className="navbar-item"
        >
          {article.node.context.title}
        </Link>
      </li>
    )
  })
  return (
    <Layout>
      <SEO
        title={pageContext.title}
        pathname={location.pathname}
        description={pageContext.excerpt}
      />
      <Helmet>
        <meta name="twitter:card" content="summary" />
        <meta property="og:type" content="article" />
      </Helmet>
      <Breadcrumbs
        depth={pageContext.depth}
        breadcrumbs={pageContext.breadcrumbs}
      />
      <TwoCol
        left={
          <div className="content">
            <h1>{pageContext.title}</h1>
            <MDXRenderer pages={allSitePage}>{body}</MDXRenderer>
          </div>
        }
        right={
          <aside className="menu">
            <ul className="menu-list">{pages}</ul>
            <SocialMenu />
          </aside>
        }
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String, $path: String) {
    mdx(id: { eq: $id }) {
      body
      id
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        type
        description
      }
      excerpt(pruneLength: 300)
    }
    allSitePage(filter: { context: { parent: { eq: $path } } }) {
      edges {
        node {
          context {
            id
            depth
            parent
            title
          }
          path
        }
      }
    }
  }
`
